import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Clearfix} from 'react-bootstrap';
import styled from 'styled-components';
import RadioButtonsAdvanced from "../../../../../components/forms/RadioButtonsAdvanced";
import InputAdvanced from "../../../../../components/forms/InputAdvanced";

class RushFeeDiscountModal extends Component {
    state = {
        rushFeeStepsType: this.props.data.rushFeeStepsType,
        rushFeePercent: this.props.data.rushFeePercent,
        discountStepsType: this.props.data.discountStepsType,
        discountPercent: this.props.data.discountPercent,
    };

    handleClose = () => {
        this.props.onClose();
    };

    handleSubmit = () => {
        const {addDiscount} = this.props;
        if (addDiscount) {
            if (this.state.discountStepsType == null || this.state.discountPercent == null) {
                alert('Nothing to save');
                return;
            }

            this.props.onSubmit(this.state.discountStepsType, this.state.discountPercent);
            return;
        }

        if (this.state.rushFeeStepsType == null || this.state.rushFeePercent == null) {
            alert('Nothing to save');
            return;
        }

        this.props.onSubmit(this.state.rushFeeStepsType, this.state.rushFeePercent);
    };

    handleReset = () => {
        const {addDiscount} = this.props;
        if (addDiscount) {
            this.props.onSubmit(null, null);
            return;
        }
        this.props.onSubmit(null, null);
    };

    handleSelectStepsGroup = (_, value) => {
        const {addDiscount} = this.props;
        if (addDiscount) {
            this.setState({
                discountStepsType: value
            });
            return;
        }

        this.setState({
            rushFeeStepsType: value
        });
    };

    handleChangePercentValue = (_, value) => {
        const {addDiscount} = this.props;
        if (addDiscount) {
            this.setState({
                discountPercent: value
            });
            return;
        }

        this.setState({
            rushFeePercent: value
        });
    };

    render() {
        const {rushFeeStepsType, rushFeePercent, discountStepsType, discountPercent} = this.state;
        const {addDiscount} = this.props;

        const stepsType = addDiscount ? discountStepsType : rushFeeStepsType;
        const percentValue = addDiscount ? discountPercent : rushFeePercent;

        const label1 = addDiscount ? 'Add a discount to:' : 'Add a rush fee to:';
        const label2 = addDiscount ? 'Discount %' : 'Rush fee %';
        const label3 = addDiscount ? 'Discount' : 'Rush fee';

        return (
            <Modal
                className="reorder-quote-project-groups-modal"
                show={true}
                onHide={this.handleClose}
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{addDiscount ? 'Discount' : 'Rush Fee'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalContainerStyled className="container-fluid">
                        <Clearfix>
                            <RadioButtonsAdvanced
                                label={label1}
                                labelSeparator=""
                                name="user_type"
                                options={[
                                    {value: 'BILLABLE_STEPS', name: 'All billable steps'},
                                    {value: 'QC_STEPS', name: 'QC steps'},
                                ]}
                                value={stepsType}
                                onChange={this.handleSelectStepsGroup}
                            />
                        </Clearfix>
                        <div>
                            <InputAdvanced
                                labelSeparator=""
                                label={label2}
                                value={percentValue || ''}
                                type="text"
                                onChange={this.handleChangePercentValue}
                                onBlur={this.handleChangePercentValue}
                                forceReset
                            />
                        </div>
                    </ModalContainerStyled>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonStyled
                        bsStyle="primary"
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </ButtonStyled>
                    <ButtonStyled bsStyle="primary" onClick={this.handleReset} >Remove {label3}</ButtonStyled>
                    <ButtonStyled bsStyle="default" onClick={this.handleClose}>Cancel</ButtonStyled>
                </Modal.Footer>
            </Modal>
        );
    }
}

const ButtonStyled = styled(Button)`
    border: none !important;
    outline: none !important;
    box-shadow: none;
    border-radius: 100px;

    &.btn-primary {
      padding-left: 20px;
      padding-right: 20px;
      background: #33333F;
      opacity: 0.8;
    }
`;

const ModalContainerStyled = styled.div`
  div {
    margin-bottom: 10px;
  }
`;

RushFeeDiscountModal.propTypes = {
    addDiscount: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired
};

export default RushFeeDiscountModal;